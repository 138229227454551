<template>
  <div class="white-dbg">
    <h1>Debug application</h1>

    <p>
      Statut de l'application

      <ul>
        <li>
          Geoloc active dans la conf de l'application ?  {{ geolocInConfig }}
        </li>
        <li>Géolocalisation active dans le navigateur ? {{ browsergeoloc }}</li>
        <li>Erreur au démarrage du watcher de geolocalisation ? {{ userLocErr }}</li>
        <li>Position actuelle : {{ userLocStr }}</li>
        <li>Equipe actuelle: <code>{{ curTeam }}</code></li>
      </ul>
    </p>
    <div v-if="userLocErr === true">
      <button
        v-if="userLocErr"
        @click="requestGeoloc"
      >
        Demander la géoloc
      </button>
      <p>Contenu erreur</p>
      <code v-if="geolocError">{{ geolocError.code }}</code>
      <p>Position trouvée</p>
      <code>{{ currentPos }}</code>
    </div>
  </div>
</template>
<script>
import { useConfigStore } from '../pinia/configuration';
import { usePoiStore } from '../pinia/poi';
import { useGameStore } from '../pinia/game';
import * as k from '../constants';

export default {
  setup() {
    const configStore = useConfigStore();
    const gameStore = useGameStore();
    const poiStore = usePoiStore();
    return {
      appConfig: configStore.appConfig,
      gameStore,
      poiStore,
    };
  },
  data() {
    return {
      geolocError: null,
      currentPos: null,
    };
  },
  computed: {
      userLocationError(){ return this.poiStore.locationStatus !== k.LOCATION_STATUS.OK},
      userLocation(){ return this.poiStore.currentLocation },
      team() {return this.gameStore.currentTeam },

    geolocInConfig() {
      return this.appConfig.enableGeolocation === true ? 'Oui' : 'Non';
    },
    browsergeoloc() {
      return 'geolocation' in navigator ? 'Oui' : 'Non';
    },
    userLocErr() {
      return this.userLocationError ? this.userLocationError : 'Non';
    },
    userLocStr() {
      return `${this.userLocation}`;
    },
    curTeam() {
      return this.team ? JSON.stringify(this.team) : 'non connecté';
    },
  },
  mounted() {
    if (this.appConfig.enableGeolocation === true) {
      if (this.userLocationError === true) {
        return;
      }
      this.poiStore.startLocationWatcher();
    }
  },
  unmounted() {
    this.poiStore.stopLocationWatcher();
  },
  methods: {
    requestGeoloc() {
      const locationParams = {
        enableHighAccuracy: true,
        maximumAge: 3000,
        timeout: 30000,
      };
      navigator.geolocation.getCurrentPosition(
        (newPos) => {
          this.currentPos = newPos;
        }, (error) => {
          this.geolocError = error;
        }, locationParams,
      );
    },
  },
};
</script>
<style>
.white-dbg {
  background-color: white;
  margin: 0 auto;
  padding-left: 30px;
}
</style>
